import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I developed and regularly teach a three-day training course on QGIS programming with Python. This
training course is taught in partnership with `}<ExternalLink href="https://www.naturalgis.pt/" mdxType="ExternalLink">{`naturalgis`}</ExternalLink>{`,
a portuguese company that provides GIS services around QGIS.`}</p>
    <p>{`It covers a range of topics, going from basic Python syntax to GUI concepts and Qt5 to QGIS API.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      